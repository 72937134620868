import * as React from "react";
import { useState } from "react";
import { Button, useNotify, useRefresh } from "react-admin";
import { useRecordContext } from "ra-core";
import { Payments } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Button as MuiButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { OwOrderApi } from "../../api/OwOrderApi";

export interface SurplusDeficitCancelButtonProps {}

export default function SurplusDeficitCancelButton({}: SurplusDeficitCancelButtonProps) {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  const onSubmit = async () => {
    await OwOrderApi.manualCancel({
      owOrderId: record.id.toString(),
    });

    notify("操作成功");
    handleClose();
  };

  return (
    <>
      <Button
        disabled={!(record.status === "INIT" && !!record.manualControl)}
        onClick={() => {
          setTimeout(() => {
            handleClickOpen();
          });
        }}
        label={"取消设置"}
      >
        <Payments />
      </Button>
      {open && (
        <Dialog
          open={open}
          fullWidth
          maxWidth={"xs"}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">取消</DialogTitle>
          <DialogContent dividers>
            <Box component="form" id={"create"} onSubmit={() => onSubmit()}>
              <Typography variant="subtitle1" color="textSecondary">
                确认取消手动设置吗?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <MuiButton onClick={handleClose}>关闭</MuiButton>
            <MuiButton form={"create"} type="submit">
              确定
            </MuiButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
