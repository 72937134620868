import qs from "qs";
import { DataProvider } from "ra-core";
import { fetchJson } from "../utils/fetch";
import { GetManyReferenceParams } from "ra-core/dist/cjs/types";
import { formatDateTime } from "../utils/formatDateTime";

export default (apiUrl: string): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      pageSize: perPage,
      page: page - 1,
      sortDir: order,
      sortField: field,
      filter: params.filter,
    };
    const url = `${apiUrl}/${resource}`;
    const options = {
      method: "POST",
      body: new URLSearchParams(qs.stringify(query, { arrayFormat: "repeat" })),
    };

    return fetchJson(url, options).then(({ data }) => {
      return {
        data: data.content,
        total: data.totalElements,
      };
    });
  },

  delete: (resource, params) => {
    const options = {
      method: "DELETE",
    };
    const url = `${apiUrl}/${resource}/${params.id}`;
    return fetchJson(url, options).then(({ data }) => {
      return { data };
    });
  },
  deleteMany: (resource, params) => {
    const options = {
      method: "DELETE",
    };
    const url = `${apiUrl}/${resource}/${params.ids.join(",")}`;
    return fetchJson(url, options).then(({ data }) => {
      return { data: params.ids };
    });
  },
  getMany: (resource, params) => {
    if (resource === "coins") {
      return fetchJson(
        `${apiUrl}/${resource}/symbol/all/${params.ids.join(",")}`
      );
    }
    return fetchJson(`${apiUrl}/${resource}/all/${params.ids.join(",")}`);
  },
  getManyReference: (resource, params: GetManyReferenceParams) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      pageSize: perPage,
      page: page - 1,
      sortDir: order,
      sortField: field,
      filter: { ...params.filter, [params.target]: params.id },
    };
    const url = `${apiUrl}/${resource}`;
    const options = {
      method: "POST",
      body: new URLSearchParams(qs.stringify(query, { arrayFormat: "repeat" })),
    };

    return fetchJson(url, options).then(({ data }) => {
      return {
        data: data.content,
        total: data.totalElements,
      };
    });
  },
  // @ts-ignore
  getOne: (resource, params) => {
    return fetchJson(`${apiUrl}/${resource}/${params.id}`);
  },
  // @ts-ignore
  create: (resource, params) => {
    console.log("create: ", params.data);
    const options = {
      method: "POST",
      body: qs.stringify(params.data, {
        allowDots: true,
        skipNulls: true,
        serializeDate: (d: Date) => formatDateTime(d),
      }),
    };
    const url = `${apiUrl}/${resource}/create`;
    return fetchJson(url, options).then(({ data }) => {
      return { data };
    });
  },
  update: (resource, params) => {
    const options = {
      method: "POST",
      body: qs.stringify(params.data, {
        allowDots: true,
        skipNulls: true,
        serializeDate: (d: Date) => formatDateTime(d),
      }),
    };
    const url = `${apiUrl}/${resource}/${params.id}`;
    return fetchJson(url, options).then(({ data }) => {
      return { data };
    });
  },
  updateMany: () => {
    return Promise.resolve({});
  },
});
