import { DeleteButton, WrapperField } from "react-admin";
import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";
import BrightnessMediumIcon from "@mui/icons-material/BrightnessMedium";
import { ExchangeOrderDirectionChoices } from "../../model/exchange";
import { OwOrderStatusChoices } from "../../model/ow";
import { BooleanTypeChoices } from "../../model/Core";

const fields: MyField[] = [
  Field.objectId(),
  Field.editSelect({
    label: "方向",
    source: "direction",
    choices: ExchangeOrderDirectionChoices,
  }),
  Field.editSelect({
    label: "结果",
    source: "resultType",
    choices: OwOrderStatusChoices,
  }),
  Field.select({
    label: "运行结束",
    source: "timeEnd",
    choices: BooleanTypeChoices,
    isUpdate: false,
    isEdit: false,
    isCreate: false,
  }),
  Field.editDate({
    label: "开始时间",
    source: "startDateTime",
  }),
  Field.editDate({
    label: "结束时间",
    source: "endDateTime",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        rowActions={[<DeleteButton />]}
        tableActions={
          <WrapperField>
            <DeleteButton />
          </WrapperField>
        }
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: BrightnessMediumIcon,
};

export default resource;
