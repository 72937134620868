import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import { OwOrderStatus } from "../model/ow";

export const OwOrderApi = {
  manualResult: async ({
    owOrderId,
    resultType,
  }: {
    owOrderId: string;
    resultType: OwOrderStatus;
  }): Promise<string> => {
    const { data } = await fetchJson(`${API_URL}/owOrder/manualResult`, {
      method: "POST",
      body: new URLSearchParams({
        owOrderId,
        resultType,
      }),
    });
    return data;
  },
  manualCancel: async ({
    owOrderId,
  }: {
    owOrderId: string;
  }): Promise<string> => {
    const { data } = await fetchJson(
      `${API_URL}/owOrder/manualCancel${owOrderId}`,
      {
        method: "POST",
      }
    );
    return data;
  },
};
