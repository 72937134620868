import dayjs from "dayjs";
import isUndefined from "lodash/isUndefined";

export const template = "YYYY-MM-DD HH:mm:ss";

export const formatDateTime = (
  value: string | Date | dayjs.Dayjs | undefined
) => {
  if (value == null || value === "" || isUndefined(value)) {
    return "";
  }
  if (dayjs.isDayjs(value)) {
    return value.format(template);
  }
  console.log("formatDateTime: ", value, dayjs(value).format(template));
  return dayjs(value).format(template);
};
