import polyglotI18nProvider from "ra-i18n-polyglot";
import {
  Admin,
  CustomRoutes,
  memoryStore,
  Resource,
  useNotify,
} from "react-admin";
import { Route } from "react-router";

import Polyglot from "node-polyglot";
import authProvider from "./authProvider";
import { API_URL } from "./config";
import Configuration from "./configuration/Configuration";
import { MyDashboard } from "./dashboard";
import HttpDataProvider from "./dataProvider/HttpDataProvider";
import chineseMessages from "./i18n/ch";
import { Layout, Login } from "./layout";
import { lightTheme } from "./layout/themes";
import users from "./pages/User";
import userWallets from "./pages/user/UserWallet";
import exchangeOrders from "./pages/exchange/ExchangeOrder";
import { getMarginsResource } from "./pages/exchange/ExchangeMargin";
import fundTermUsers from "./pages/fund/FundTermUser";
import fundCurrentUsers from "./pages/fund/FundCurrentUser";
import nftOrders from "./pages/nft/NftOrder";
import MySecurity from "./pages/user/MySecurity";
import OwOrder from "./pages/ow/OwOrder";
import UserSetting from "./pages/ow/UserSetting";
import OwUserSd from "./pages/ow/OwUserSd";
import OwAgentSd from "./pages/ow/OwAgentSd";
import AgentSetting from "./pages/ow/AgentSetting";

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    return chineseMessages;
  },
  "ch",
  {
    onMissingKey: (
      key: string,
      options: Polyglot.InterpolationOptions,
      locale: string
    ) => key,
  }
);

function NewComponent() {
  let notify = useNotify();

  console.log(notify);
  debugger;

  window.addEventListener("error", (error) => {
    console.log("捕获到异常：", error.error);
    notify(error.error);
  });
  window.addEventListener("unhandledrejection", (error) => {
    console.log("捕获到异常：", error.reason.message);
    notify(error.reason.message);
  });

  return <div></div>;
}

const App = () => {
  return (
    <Admin
      title="管理后台"
      dataProvider={HttpDataProvider(API_URL)}
      authProvider={authProvider}
      dashboard={MyDashboard}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      disableTelemetry
      store={memoryStore()}
      theme={lightTheme}
    >
      <CustomRoutes>
        <Route path="/configuration" element={<Configuration />} />
      </CustomRoutes>
      <Resource name="users" {...users} />
      <Resource name="userWallets" {...userWallets} />
      <Resource name="exchangeOrders" {...exchangeOrders} />
      {/*<Resource name="exchangeTrades" {...exchangeTrades} />*/}

      <Resource
        name="exchangeMarginsLeverage"
        {...getMarginsResource("LEVERAGE")}
      />
      <Resource
        name="exchangeMarginsContract"
        {...getMarginsResource("CONTRACT")}
      />

      <Resource name="nftOrders" {...nftOrders} />
      <Resource name="fundTermUsers" {...fundTermUsers} />
      <Resource name="fundCurrentUsers" {...fundCurrentUsers} />

      <Resource name="authKey" {...MySecurity} />

      <Resource name="owOrder" {...OwOrder} />
      <Resource name="userSetting" {...UserSetting} />
      <Resource name="owUserSd" {...OwUserSd} />
      <Resource name="owAgentSd" {...OwAgentSd} />
      <Resource name="agentSetting" {...AgentSetting} />
    </Admin>
  );
};

export default App;
