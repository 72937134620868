import * as React from "react";
import { useState } from "react";
import { Button, useNotify, useRefresh } from "react-admin";
import { useRecordContext } from "ra-core";
import { Payments } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Button as MuiButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { OwOrderStatus } from "../../model/ow";
import { OwOrderApi } from "../../api/OwOrderApi";

export interface SurplusDeficitButtonProps {
  type: OwOrderStatus;
}

export default function SurplusDeficitButton({
  type,
}: SurplusDeficitButtonProps) {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  const onSubmit = async () => {
    await OwOrderApi.manualResult({
      owOrderId: record.id.toString(),
      resultType: type,
    });

    notify("操作成功");
    handleClose();
  };

  let label = type === "WIN" ? "盈利" : "亏损";
  return (
    <>
      <Button
        disabled={!(record.status === "INIT" && !record.manualControl)}
        onClick={() => {
          setTimeout(() => {
            handleClickOpen();
          });
        }}
        label={label}
      >
        <Payments />
      </Button>
      {open && (
        <Dialog
          open={open}
          fullWidth
          maxWidth={"xs"}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">{label}</DialogTitle>
          <DialogContent dividers>
            <Box component="form" id={"create"} onSubmit={() => onSubmit()}>
              <Typography variant="subtitle1" color="textSecondary">
                确认手动
                <Typography
                  component={"span"}
                  color={(theme) => ({
                    color: type === "WIN" ? "green" : "red",
                  })}
                >
                  {label}
                </Typography>
                吗?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <MuiButton onClick={handleClose}>关闭</MuiButton>
            <MuiButton form={"create"} type="submit">
              确定
            </MuiButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
