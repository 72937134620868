import { buildField } from "../Field";
import { MyField } from "../../components/types";
import { DateField } from "react-admin";

export interface DataTimeArgs {
  label: string;
  source: string;
  transform?: (value: any) => Date | undefined;
}

export function dataTime(args: DataTimeArgs, field?: MyField) {
  return buildField(
    {
      minWidth: 140,
      label: args.label,
      source: args.source,

      component: DateField,
      isFilter: false,
      isEdit: false,
      sortable: false,
      props: {
        showTime: true,
        transform: args.transform,
      },
    },
    field
  );
}
