import { MyField } from "../../components/types";
import { DateField, DateTimeInput } from "react-admin";
import { base, BaseArgs } from "./base";
import { AmountArgs } from "./amount";
import dayjs from "dayjs";
import { formatDateTime } from "../formatDateTime";

export interface EditDataArgs extends BaseArgs {}

const defaultArgs: Partial<AmountArgs> = {
  isRequired: true,
  fullWidth: true,
  isFilter: false,
  sortable: true,
  isEdit: true,
  isUpdate: true,
};

export function editDate(args: EditDataArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: DateField,
      editComponent: DateTimeInput,
      props: {
        showTime: true,
      },
      editProps: {
        parse: (value: string) => {
          return dayjs(value).toDate();
        },
        format: formatDateTime,
      },
      filterProps: {
        alwaysOn: true,
      },
    },
    field
  );
}
