import dayjs, { isDayjs } from "dayjs";
import isNumber from "lodash/isNumber";

export const BaseDateTimeTemplate = "YYYY-MM-DD HH:mm:ss";
export const BaseDateTemplate = "YYYY-MM-DD";

const SourceFormatTemplate = "YYYY-MM-DDTHH:mm:ss.SSS";

// toDayjs(date).tz(timeZone.id).format(BaseDateTimeTemplate)
// { id: "America/New_York", name: "美国东部", offset: "-05:00" }

export const DEFAULT_TIMEZONE = "America/New_York";

export function toUsDate(date?: string) {
  if (!date) {
    return undefined;
  }
  return dayjs(date).locale(DEFAULT_TIMEZONE).toDate();
}

export function toDayjs(date: string, timezone: string = "Asia/Shanghai") {
  if (/\.\d{3}$/.test(date)) {
    // 如果日期字符串以三位毫秒结束
    return dayjs(date, SourceFormatTemplate).locale(timezone);
  } else if (/\.\d{2}$/.test(date)) {
    // 如果日期字符串以一位或两位毫秒结束
    return dayjs(date, "YYYY-MM-DDTHH:mm:ss.SS").locale(timezone);
  } else if (/\.\d{1}$/.test(date)) {
    // 如果日期字符串以一位或两位毫秒结束
    return dayjs(date, "YYYY-MM-DDTHH:mm:ss.S").locale(timezone);
  } else if (isNumber(date)) {
    return dayjs(date);
  } else if (isDayjs(date)) {
    return date;
  } else {
    return dayjs(date, BaseDateTimeTemplate).locale(timezone);
  }
}

export function to(
  date: string | undefined,
  template: string = BaseDateTimeTemplate
) {
  if (!date) return "";
  let data = toDayjs(date);
  return data.format(template);
}

export function toString(startValue: any) {
  // return dayjs(startValue).format(BaseDateTimeTemplate);
  return to(startValue);
}

export function toStringNotBlank(startValue: any) {
  return dayjs(startValue).format("YYYY-MM-DD_HH:mm:ss");
}

export function dateFormatter(
  value: dayjs.Dayjs,
  valueType: string
): string | number {
  return toString(value);
}

export default {
  toString: toString,
  toStringNotBlank: toStringNotBlank,
};
